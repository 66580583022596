/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IERC2981, IERC2981Interface } from "../IERC2981";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_salePrice",
        type: "uint256",
      },
    ],
    name: "royaltyInfo",
    outputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "royaltyAmount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

export class IERC2981__factory {
  static readonly abi = _abi;
  static createInterface(): IERC2981Interface {
    return new utils.Interface(_abi) as IERC2981Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IERC2981 {
    return new Contract(address, _abi, signerOrProvider) as IERC2981;
  }
}
