type ChainToContractMap = {[key: number]: string | undefined};

export const CODE_MAKES_ART_ADDRESS: ChainToContractMap = {
  [1]: '0xc963047d1880CF43Fee0847f66a57E79C635B2F5',
  [4]: '0xfb4CC7FE9ad7adF225711f3A51bD1e6B189dAF69',
};

export const QUASARS_ADDRESS: ChainToContractMap = {
  [1]: '0xe76512732B1e49Eb259dfc6D9d49E4c5dAc6a75C',
  [4]: '0x3e9DA08BC85934413cf10EBAdC434DF4f958d518',
};

export const GEOMES_ADDRESS: ChainToContractMap = {
  [1]: '0xcfA42467fF2db1d9Bd501F86F6fBFD8D3EE5E6CF',
  [4]: '0xf5aabc1f7cb284652de7d49ff06a70d2a121b4a6',
};

export const QUASARS_GIVEAWAY_FOR_2_GEOMES_ADDRESS: ChainToContractMap = {
  [1]: '0x76F6277dcf916a6D456b2c8eDA9BEe9357C08824',
  [4]: '0x160afeD466ff5DBf9A9A836a68Dde9f625770bF4',
};
